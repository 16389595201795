import { RouterProvider } from "react-router-dom";
import { loginRouter, adminRouter, tivHodimRouter, notFoundRouter } from "./router";
import { theme } from "antd";
import { ConfigProvider } from "antd";
import { createContext } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { ADMIN, TIV_HODIMI } from "constants/roles";
import { Toast } from "configs/toast";

interface ThemeInterface {
  mode?: string | undefined;
  setMode?: any;
}

export const ThemeContext = createContext<ThemeInterface>({});

const App = () => {
  const { isAuth, role } = useSelector((state: any) => state.auth);
  const { mode } = useSelector((state: any) => state.antdTheme);

  function switchRole() {
    switch (role) {
      case ADMIN:
        return adminRouter;
      case TIV_HODIMI:
        return tivHodimRouter;
      default:
        return notFoundRouter;
    }
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#109768",
            colorBgContainer: mode === "dark" ? "#151515" : "#ffffff",
            colorBgBase: mode === "dark" ? "#000000" : "f0f0f0",
            fontSize: 16,
            fontFamily: "Montserrat",
          },
          algorithm: mode === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}
      >
        <Toast />
        <RouterProvider router={isAuth ? switchRole() : loginRouter} />
      </ConfigProvider>
    </>
  );
};

export default App;
