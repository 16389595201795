import dayjs from "dayjs";

type LabelFunction = (record: Record<string, any>) => string;

export const makeOptions = (data: any[], label: string | LabelFunction, value = "id") => {
  if (!data) {
    return [];
  }
  return (
    data?.map(item => ({
      label: typeof label === "function" ? label(item) : item[label],
      value: item[value],
    })) || []
  );
};

export const setUTCTime = (date: string, template?: string) => {
  return dayjs(new Date(date)).format(template || "YYYY-MM-DD H:mm");
};
