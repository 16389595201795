import { Empty, Spin, Table, Tabs, TabsProps, Typography } from "antd";
import http from "configs/http";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import queryString from "query-string";
import ReactApexChart from "react-apexcharts";
import { setUTCTime } from "utils";
import { useQuery } from "@tanstack/react-query";
import { entryProductByIdColumns } from "./columns";
import { ENDPOINTS } from "constants/endpoints";
import { QUERY_KEYS } from "constants/query-keys";
import { emptyText } from "constants/texts";

const { Title } = Typography;

interface DashboardInterface {
  max_price: string;
  min_price: string;
  created_at: string;
}

const EntryProductById = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { productName } = queryString.parse(window.location.search);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dashboardType, setDashboardType] = useState<string>("export");

  const items: TabsProps["items"] = [
    {
      key: "export",
      label: "Savdo tarmoqlardagi narx",
    },
    {
      key: "market",
      label: "Bozordagi narx",
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCTS, params.productId],
    queryFn: async () => {
      const response = await http.get(`${ENDPOINTS.API_EXPORT_WEB_PRODUCTS}${params.productId}/`);
      return response.data;
    },
  });
  const { data: dashboardData, isLoading: dashboardLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_DASHBOARD, params.productId, dashboardType],
    queryFn: async () => {
      const response = await http.get(
        `${ENDPOINTS.API_EXPORT_WEB_PRODUCTS}${params.productId}/dashboard/?type=${dashboardType}`,
      );
      return response.data;
    },
  });

  const series = useMemo(
    () => [
      {
        name: "Max Price",
        data: dashboardData?.map((item: DashboardInterface) => item?.max_price),
      },
      {
        name: "Min Price",
        data: dashboardData?.map((item: DashboardInterface) => item?.min_price),
      },
    ],
    [dashboardData],
  );

  const chartOptions = useMemo(() => {
    return {
      chart: {
        id: "basic-line",
        height: 500,
      },
      xaxis: {
        categories: dashboardData?.map((item: DashboardInterface) =>
          setUTCTime(item?.created_at, "YYYY-MM-DD"),
        ),
      },
    };
  }, [dashboardData]);

  return (
    <div>
      <Title
        level={4}
        className="flex items-center"
        style={{ whiteSpace: "nowrap", overflowX: "auto" }}
      >
        <FaArrowLeft size={24} className="mr-5 cursor-pointer" onClick={() => navigate(-1)} />{" "}
        <u className="mr-1">{productName}</u> - mahsulotining xorijiy bozorlardagi eng{" "}
        <u className="mx-1">past</u> va <u className="mx-1">baland</u> narxlari to'g'risida.
      </Title>

      <Tabs
        defaultActiveKey={dashboardType}
        items={items}
        onChange={(e: string) => setDashboardType(e)}
      />
      {dashboardLoading ? (
        <div className="text-center">
          <Spin className="mx-auto" />
        </div>
      ) : Array.isArray(dashboardData) && dashboardData.length > 0 ? (
        <ReactApexChart options={chartOptions} series={series} type="line" height={500} />
      ) : (
        <Empty className="my-5" image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
      )}

      <Table
        columns={entryProductByIdColumns(pageNumber, pageSize)}
        dataSource={data}
        className="mt-5"
        rowKey="id"
        bordered
        size="small"
        locale={{
          emptyText,
        }}
        scroll={{
          x: "300",
          y: "70vh",
        }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        onChange={(obj: any) => {
          setPageNumber(obj.current);
          setPageSize(obj.pageSize);
        }}
      />
    </div>
  );
};

export default EntryProductById;
