import { Image, TableProps } from "antd";
import { IMG_BASE_URL } from "constants/consts";

export const productsColumns = (pageNumber: number, pageSize: number): TableProps["columns"] => [
  {
    title: `#`,
    key: "numberOfRow",
    fixed: "left",
    align: "center",
    width: 60,
    render: (text: string, obj: any, index: number) => {
      return <span> {(pageNumber - 1) * pageSize + index + 1} </span>;
    },
  },
  {
    title: `Mahsulot yetishtirilgan davlat`,
    key: "where_come_from_name",
    align: "center",
    width: 200,
    render: row => {
      return (
        <>
          {row?.where_come_from_name} {row?.city_name ? `(${row?.city_name})` : ""}
        </>
      );
    },
  },
  {
    title: `Mahsulot nomi`,
    dataIndex: "product_name",
    key: "product_name",
    align: "center",
    width: 200,
  },
  {
    title: `Mahsulot navi`,
    dataIndex: "product_sort",
    key: "product_sort",
    align: "center",
    width: 200,
  },
  {
    title: `Bozordagi narxi`,
    key: "market_price",
    align: "center",
    children: [
      {
        title: `Eng past narx`,
        dataIndex: "market_min_price",
        key: "market_min_price",
        width: 170,
        align: "center",
      },
      {
        title: `Eng baland narx`,
        dataIndex: "market_max_price",
        key: "market_max_price",
        width: 170,
        align: "center",
      },
    ],
  },
  {
    title: `Ma'lumot kiritilgan sana`,
    dataIndex: "data_date",
    key: "data_date",
    align: "center",
    width: 170,
  },
  {
    title: `Rasm`,
    key: "image",
    align: "center",
    width: 100,
    render: (row: any) => {
      if (row?.product_img) {
        return <Image width={60} src={`${IMG_BASE_URL}${row?.product_img}`} />;
      }
    },
    fixed: "right",
  },
];
