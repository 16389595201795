import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { Suspense } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import store from "./store";

import "./index.css";
import "antd/dist/reset.css";
import Loader from "components/loader";
import { queryClient } from "configs/react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </Suspense>
  </>,
);
