import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Flex,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { ReactComponent as IconExcel } from "assets/icons/excel.svg";
import { UploadFile } from "components/upload";
import { useState } from "react";
import { ENDPOINTS } from "constants/endpoints";
import { AddProductByExcelModalPropsInterface } from "./props.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import http from "configs/http";
import dayjs from "dayjs";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "constants/query-keys";

const { Title, Text } = Typography;

const AddProductByExcelModal = (props: AddProductByExcelModalPropsInterface) => {
  const { isOpenModal, setIsOpenModal } = props;
  const queryClient = useQueryClient();

  const [dateString, setDateString] = useState<any>("");

  const disabledDate = (current: any) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDateString(dateString);
  };

  const onCancel = () => {
    setIsOpenModal(false);
  };

  const onSuccess = (response: any) => {
    if (response && response?.data) {
      toast.success(response?.message);
      onCancel();
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_LIST],
      });
    }
  };

  const { mutate, isPending: exampleExcelDataLoading } = useMutation({
    mutationKey: ["download-excel-template"],
    mutationFn: async () => {
      const response = await http.get(`${ENDPOINTS.API_EXPORT_WEB_UPLOAD}`, {
        params: {
          responseType: "arraybuffer",
        },
        responseType: "arraybuffer",
      });
      const url = window.URL.createObjectURL(
        new Blob([response?.data], {
          type: "application/xlsx",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `price-template.xlsx`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  });

  return (
    <>
      <Modal
        open={isOpenModal}
        footer={[]}
        centered
        width={600}
        title="Excel fayl orqali mahsulotlarni kiritish"
        closeIcon={<MdClose size={24} onClick={onCancel} />}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <DatePicker
              onChange={onChange}
              className="w-full"
              size="large"
              placeholder="Sanani tanlang"
              disabledDate={disabledDate}
            />
          </Col>
          <Col span={24}>
            <Space
              direction="vertical"
              className="p-4 w-[100%] text-center border-dashed border-1 border-[#109768] rounded-md"
            >
              <Title
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: 500,
                }}
                level={4}
              >
                Faylni yuklash
              </Title>
              <Text
                style={{
                  paddingTop: "4px",
                  paddingBottom: "10px",
                  color: "#8393A6",
                  margin: 0,
                  fontSize: "12px",
                }}
              >
                To'liq ma'lumot bilan to'ldirilgan excel faylingizni shuyerga yuklang. Maksimum 10
                mb gacha bo'lgan excel fayl yuklashingiz mumkin
              </Text>
              <UploadFile
                uploadPath={ENDPOINTS.API_EXPORT_WEB_UPLOAD}
                accept=".xls,.xlsx"
                className="w-[100%]"
                multiple={false}
                onSuccess={(response: any) => {
                  onSuccess(response);
                }}
                dateString={dateString}
              />
            </Space>
          </Col>

          <Col span={24}>
            <Space
              direction="vertical"
              className="p-4 w-[100%] border-dashed border-1 border-[gray] rounded-md"
            >
              <Space className="flex flex-col xl:flex-row lg:flex-row md:flex-row items-center">
                <div>
                  <Flex align="center" gap="8px">
                    <IconExcel />{" "}
                    <Title
                      style={{
                        margin: 0,
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                      level={4}
                    >
                      Namuna fayli
                    </Title>
                  </Flex>
                  <p
                    style={{
                      paddingTop: "10px",
                      color: "#8393A6",
                      margin: 0,
                    }}
                  >
                    Keltirilgan namunadagi excel faylni yuklab olishingiz va uni shaxsiy faylingiz
                    uchun boshlang'ich fayl sifatida ishlatishingiz mumkin
                  </p>
                </div>
                <Button onClick={() => mutate()} loading={exampleExcelDataLoading}>
                  Yuklab olish
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AddProductByExcelModal;
