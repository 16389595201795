import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, message } from "antd";
import http from "configs/http";
import { currencyUnits, productPackagingUnits } from "constants/consts";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { CountryInterface, PackagesInterface, ProductInterface } from "types/server-data.types";
import { makeOptions } from "utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ENDPOINTS } from "constants/endpoints";
import { QUERY_KEYS } from "constants/query-keys";
import PackageModal from "../package-modal";
import { AddProductInfoModalInterface } from "./props.types";

const AddProductInfo = (props: AddProductInfoModalInterface) => {
  const { isOpenModal, setIsOpenModal, productInfo } = props;

  const [isOpenPackageModal, setIsOpenPackageModal] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState<string>("");

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const getUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const { data: countries, isLoading: countriesLoading } = useQuery<CountryInterface[]>({
    queryKey: [QUERY_KEYS.GET_COMMON_COUNTRIES, { all: true }],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_COMMON_COUNTRIES, {
        params: {
          all: true,
        },
      });
      return response.data;
    },
  });

  const { data: packages, isLoading: packagesLoading } = useQuery<PackagesInterface[]>({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCT_PACKAGES],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_EXPORT_WEB_PRODUCT_PACKAGES);
      return response.data;
    },
  });

  const { mutate: submitForm, isPending } = useMutation({
    mutationKey: [QUERY_KEYS.POST_EXPORT_WEB_PRODUCT_PRICE_DATA],
    mutationFn: async (data: any) => {
      return await http.post(ENDPOINTS.API_EXPORT_WEB_PRODUCT_PRICE_DATA, data);
    },
    onSuccess: (res: any) => {
      messageApi.success("Muvaffaqqiyatli!");
      setIsOpenPackageModal(false);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCTS],
      });
      setIsOpenModal(false);
    },
    onError: (err: any) => {
      messageApi.error("Xatolik!");
    },
  });

  const onFinish = (values: ProductInterface) => {
    values.image = JSON.stringify({ image: imageBase64 });
    values.product = productInfo?.id;
    submitForm(values);
  };

  return (
    <Modal
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      title={productInfo?.product_name}
      footer={null}
      width={1200}
      centered
    >
      {contextHolder}

      {isOpenPackageModal ? (
        <PackageModal
          isOpenPackageModal={isOpenPackageModal}
          setIsOpenPackageModal={setIsOpenPackageModal}
        />
      ) : null}

      <Form
        name="nest-messages"
        onFinish={onFinish}
        style={{ maxWidth: 1200 }}
        layout="vertical"
        encType="multipart/form-data"
      >
        <Row gutter={[12, 12]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name={"product_sort"}
              label="Navi"
              rules={[
                {
                  required: true,
                  message: "Iltimos navini kiriting",
                },
              ]}
            >
              <Input placeholder="Navi" />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name={"where_come_from"}
              label="Mahsulot yetishtirilgan davlat"
              rules={[
                {
                  required: true,
                  message: "Iltimos mahsulot yetishtirilgan davlatni tanlang!",
                },
              ]}
            >
              <Select
                placeholder="Tanlang"
                options={makeOptions(countries ?? [], "name")}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label || "").toLowerCase().includes(input.toLowerCase())
                }
                loading={countriesLoading}
              />
            </Form.Item>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="flex items-center justify-between">
              <Button
                className="mr-2 mt-2"
                type="primary"
                onClick={() => setIsOpenPackageModal(true)}
              >
                <MdAdd size={20} />
              </Button>

              <Form.Item
                name={"product_packaging"}
                label="Mahsulot qadog'i"
                rules={[
                  {
                    required: true,
                    message: "Iltimos mahsulot qadog'ini tanlang",
                  },
                ]}
                className="w-[100%]"
              >
                <Select
                  placeholder="Tanlang"
                  options={makeOptions(packages ?? [], "title")}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "").toLowerCase().includes(input.toLowerCase())
                  }
                  loading={packagesLoading}
                  className="w-[100%]"
                />
              </Form.Item>
            </div>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"product_packaging_size"}
              label="Mahsulot miqdori"
              rules={[
                {
                  required: true,
                  message: "Iltimos mahsulot miqdorini kiriting",
                },
              ]}
            >
              <InputNumber className="w-[100%]" min={0} placeholder="0" />
            </Form.Item>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"product_packaging_unit"}
              label="Mahsulot o'lchov birligi"
              rules={[
                {
                  required: true,
                  message: "Iltimos mahsulot o'lchov birligini tanlang",
                },
              ]}
            >
              <Select
                placeholder="Tanlang"
                options={productPackagingUnits}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label || "").toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          <Divider />

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"market_min_price"}
              label="Bozordagi eng past narx"
              rules={[
                {
                  required: true,
                  message: "Iltimos bozordagi eng past narxni kiriting",
                },
              ]}
            >
              <InputNumber className="w-[100%]" min={0} placeholder="0" />
            </Form.Item>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"producmarket_max_pricet_sort"}
              label="Bozordagi eng baland narx"
              rules={[
                {
                  required: true,
                  message: "Iltimos bozordagi eng baland narxni kiriting",
                },
              ]}
            >
              <InputNumber className="w-[100%]" min={0} placeholder="0" />
            </Form.Item>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"market_currency_type"}
              label="Valyuta"
              rules={[
                {
                  required: true,
                  message: "Iltimos valyutani tanlang",
                },
              ]}
            >
              <Select
                placeholder="Tanlang"
                options={currencyUnits}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label || "").toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          <Divider />

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"export_min_price"}
              label="Savdo tarmoqlaridagi eng past narx"
              rules={[
                {
                  required: true,
                  message: "Iltimos savdo tarmoqlardagi eng past narxni kiriting",
                },
              ]}
            >
              <InputNumber className="w-[100%]" min={0} placeholder="0" />
            </Form.Item>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"export_max_price"}
              label="Savdo tarmoqlaridagi eng baland narx"
              rules={[
                {
                  required: true,
                  message: "Iltmos savdo tarmoqlaridagi eng baland narxni kiriting",
                },
              ]}
            >
              <InputNumber className="w-[100%]" min={0} placeholder="0" />
            </Form.Item>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name={"export_currency_type"}
              label="Valyuta"
              rules={[
                {
                  required: true,
                  message: "Iltimos valyutani tanlang",
                },
              ]}
            >
              <Select
                placeholder="Tanlang"
                options={currencyUnits}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label || "").toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          <Divider />

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name={"image"}
              label="Rasm"
              rules={[
                {
                  required: true,
                  message: "Iltimos rasm yuklang!",
                },
              ]}
            >
              <div className="img-wrap">
                {imageBase64 ? null : <MdAdd className={"icoon"} />}
                <input type="file" accept=".jpg, .jpeg, .png" onChange={getUrl} required />
                {imageBase64 ? <img src={imageBase64} alt="Preview" /> : null}
              </div>
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item name={"description"} label="Izoh">
              <Input.TextArea placeholder="Izoh" rows={5} />
            </Form.Item>
          </Col>

          <Divider />
        </Row>
        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" className="w-[300px]" loading={isPending}>
            Saqlash
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProductInfo;
