import { useSelector } from "react-redux";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Define default toast options
const toastConfig: ToastContainerProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

export const Toast = () => {
  const { mode } = useSelector((state: any) => state.antdTheme);

  return <ToastContainer {...toastConfig} theme={mode} />;
};
