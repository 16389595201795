import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, Modal, message } from "antd";
import http from "configs/http";
import { ENDPOINTS } from "constants/endpoints";
import { QUERY_KEYS } from "constants/query-keys";
import { PackagesInterface } from "types/server-data.types";
import { PackageModalInterface } from "./props.types";

const PackageModal = (props: PackageModalInterface) => {
  const queryClient = useQueryClient();

  const { isOpenPackageModal, setIsOpenPackageModal } = props;

  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: submitForm, isPending } = useMutation({
    mutationKey: [QUERY_KEYS.POST_EXPORT_WEB_PRODUCT_PACKAGES],
    mutationFn: async (data: PackagesInterface): Promise<PackagesInterface> => {
      return await http.post(ENDPOINTS.API_EXPORT_WEB_PRODUCT_PACKAGES, data);
    },
    onSuccess: (res: any) => {
      messageApi.success("Muvaffaqqiyatli!");
      setIsOpenPackageModal(false);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCT_PACKAGES],
      });
    },
    onError: (err: any) => {
      messageApi.error("Xatolik!");
    },
  });

  const onFinish = (values: PackagesInterface) => {
    submitForm(values);
  };

  return (
    <Modal
      open={isOpenPackageModal}
      onCancel={() => setIsOpenPackageModal(false)}
      title={"Yangi mahsulot qadog'i qo'shish"}
      footer={null}
      width={600}
      centered
    >
      {contextHolder}
      <Form name="nest-messages" onFinish={onFinish} style={{ maxWidth: 600 }} layout="vertical">
        <Form.Item
          name={"title"}
          label="Qadoq nomi"
          rules={[
            {
              required: true,
              message: "Iltimos qadoq nomini kiriting",
            },
          ]}
        >
          <Input placeholder="Qadoq nomi" />
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" className="w-[300px]" loading={isPending}>
            Qo'shish
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PackageModal;
