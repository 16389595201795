import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import http from "configs/http";
import { ENDPOINTS } from "constants/endpoints";
import { QUERY_KEYS } from "constants/query-keys";

export const useCourseMoney = () => {
  return useQuery({
    queryKey: ["course-money"],
    queryFn: async () => {
      const response = await axios.get(`https://cbu.uz/uz/arkhiv-kursov-valyut/json/`);
      return response?.data;
    },
  });
};

export const useCountries = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_COMMON_COUNTRIES],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_COMMON_COUNTRIES);
      return response?.data;
    },
  });
};

export const useCities = (countryId: number | string | null, countries?: any) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_COMMON_CITIES, countryId],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_COMMON_CITIES, {
        params: {
          country_id: countryId,
        },
      });
      return response?.data;
    },
    enabled: !!countries && !!countryId,
  });
};

export const useExportCountries = (productId: string | number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_COMMON_EXPORT_COUNTRIES, productId],
    queryFn: async () => {
      const response = await http.get(`${ENDPOINTS.API_COMMON_EXPORT_COUNTRIES}${productId}/`);
      return response?.data;
    },
    enabled: !!productId,
  });
};

export const useExportWebProducts = (countryId?: any) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCTS, countryId],
    queryFn: async () => {
      const response = await http.get(
        `${ENDPOINTS.API_EXPORT_WEB_PRODUCTS}?country_id=${countryId ?? ""}`,
      );
      return response.data;
    },
  });
};

export const useExportWebProductsPrices = (filter: any) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCTS_PRICES, filter],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_EXPORT_WEB_PRODUCTS_PRICES, {
        params: filter,
      });
      return response.data;
    },
  });
};

export const useExportWebUploadList = (filter: any) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_LIST, filter],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_EXPORT_WEB_UPLOAD_LIST, {
        params: filter,
      });
      return response.data;
    },
  });
};

export const useExportWebUploadDetail = (filter: any, productId: number | string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_DETAIL, filter, productId],
    queryFn: async () => {
      const response = await http.get(`${ENDPOINTS.API_EXPORT_WEB_UPLOAD_DETAIL}${productId}/`, {
        params: filter,
      });
      return response.data;
    },
  });
};
