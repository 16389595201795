import { Button, Col, Row, Table, Typography } from "antd";
import { useMemo, useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";

import { excelDataColumns } from "./columns";
import AddProductByExcelModal from "./modals/add-product-by-excel-modal";
import UploadedProductDetailModal from "./modals/uploaded-product-detail-modal";
import { emptyText } from "constants/texts";
import { useExportWebUploadList } from "hooks";

const { Title } = Typography;

const ExcelData = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [productId, setProductId] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>("");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);
  const [rowStatus, setRowStatus] = useState<string>("");

  const openDetailModal = (row: any) => {
    setRowStatus(row?.status);
    setProductId(row?.id);
    setIsOpenDetailModal(true);
  };

  // FILTER
  const filter = useMemo(() => {
    return {
      page: pageNumber,
      page_size: pageSize,
      status: status,
    };
  }, [pageNumber, pageSize, status]);

  // HOOKS
  const { data, isLoading } = useExportWebUploadList(filter);

  return (
    <div>
      {isOpenModal ? (
        <AddProductByExcelModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
      ) : null}

      {productId && isOpenDetailModal ? (
        <UploadedProductDetailModal
          isOpenDetailModal={isOpenDetailModal}
          setIsOpenDetailModal={setIsOpenDetailModal}
          productId={productId}
          rowStatus={rowStatus}
        />
      ) : null}

      <Title style={{ whiteSpace: "nowrap", overflowX: "auto" }} level={4}>
        Excel ma'lumotlari
      </Title>
      <Row gutter={[16, 16]}>
        <Col className="ml-auto">
          <Button type="primary" onClick={() => setIsOpenModal(true)}>
            <RiFileExcel2Line size={20} />
            Exceldan kiritish
          </Button>
        </Col>
      </Row>

      <Table
        columns={excelDataColumns(pageNumber, pageSize, openDetailModal, status, setStatus)}
        dataSource={data?.results}
        className="mt-5"
        rowKey="id"
        bordered
        size="small"
        locale={{
          emptyText,
        }}
        scroll={{
          x: "300",
          y: "60vh",
        }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100"],
          total: data?.count,
        }}
        onChange={(obj: any) => {
          setPageNumber(obj.current);
          setPageSize(obj.pageSize);
        }}
      />
    </div>
  );
};

export default ExcelData;
