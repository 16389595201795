import { QueryCache, QueryClient, MutationCache } from "@tanstack/react-query";
import { toast } from "react-toastify";

const mutationCache = new MutationCache({
  onError: res => {
    const error: any = res;
    console.log(error);
    toast.error(
      error?.response?.data?.detail ??
        error?.response?.statusText ??
        error?.message ??
        "Xatolik yuz berdi",
    );
  },
});

const queryCache = new QueryCache({
  onError: res => {
    const error: any = res;
    console.log(error);
    toast.error(
      error?.response?.data?.detail ??
        error?.response?.statusText ??
        // `${error?.config?.url} ${error?.message}` ??
        error?.message ??
        "Xatolik yuz berdi",
    );
  },
});

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // retry(failureCount, res) {
      //     const error: any = res;
      //     if (error?.status === 404) return false;
      //     if (failureCount < 1) return true;
      //     return false;
      // },
    },
  },
});
