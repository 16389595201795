import { useLocalStorageState } from "ahooks";
import { Col, DatePicker, Pagination, Row, Select, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { get } from "lodash";

import { makeOptions } from "utils";
import { productsColumns } from "./columns";
import { emptyText } from "constants/texts";
import { useCountries, useExportWebProducts, useExportWebProductsPrices } from "hooks";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Products = () => {
  const setDefaultDate = true;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [countryId, setCountryId] = useLocalStorageState("countryId", {
    defaultValue: null,
  });
  const [productId, setProductId] = useLocalStorageState("productId", {
    defaultValue: null,
  });
  const defaultDates = useMemo(() => [dayjs().subtract(30, "day"), dayjs()], []);
  const [dateStrings, setDateStrings] = useLocalStorageState("dateStrings", {
    defaultValue: setDefaultDate ? defaultDates.map(date => date.format("YYYY-MM-DD")) : ["", ""],
  });
  const [dateRangeValue, setDateRangeValue] = useLocalStorageState("dateRangeValue", {
    defaultValue: setDefaultDate
      ? [dayjs(get(dateStrings, "[0]", "")), dayjs(get(dateStrings, "[1]", ""))]
      : undefined,
  });

  const onChangeRange = (dates: any, dateStrings: any) => {
    setDateStrings(dateStrings);
    setDateRangeValue(dates);
  };

  // FILTER
  const filter = useMemo(() => {
    return {
      country_id: countryId,
      product_id: productId,
      from_date: dateStrings ? dateStrings[0] : null,
      to_date: dateStrings ? dateStrings[1] : null,
      page: pageNumber,
      page_size: pageSize,
    };
  }, [countryId, dateStrings, productId, pageNumber, pageSize]);

  // HOOKS
  const { data: countries, isLoading: countriesLoading } = useCountries();
  const { data: products, isLoading: productsLoading } = useExportWebProducts(countryId);
  const { data, isLoading } = useExportWebProductsPrices(filter);

  return (
    <div>
      <Title style={{ whiteSpace: "nowrap", overflowX: "auto" }} level={4}>
        Eksportbop meva-sabzavot mahsulotlarining xorijiy bozorlardagi eng past va baland narxlari
        to'g'risida.
      </Title>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={6} md={8} sm={12} xs={24} className="ml-auto">
          <RangePicker
            className="w-[100%]"
            onChange={onChangeRange}
            placeholder={["-dan", "-gacha"]}
            value={
              dateRangeValue
                ? [dayjs(get(dateRangeValue, "[0]", "")), dayjs(get(dateRangeValue, "[1]", ""))]
                : undefined
            }
          />
        </Col>

        <Col xl={6} lg={6} md={8} sm={12} xs={24}>
          <Select
            className="w-[100%]"
            placeholder="Davlatni tanlang"
            options={makeOptions(countries ?? [], "name")}
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label || "").toLowerCase().includes(input.toLowerCase())
            }
            loading={countriesLoading}
            value={countryId}
            onChange={e => {
              setCountryId(e);
              setPageNumber(1);
            }}
          />
        </Col>

        <Col xl={6} lg={6} md={8} sm={12} xs={24}>
          <Select
            className="w-[100%]"
            placeholder="Mahsulotni tanlang"
            options={products?.map((product: any) => {
              return {
                value: product?.id,
                label: `${product?.product_name ?? ""} ${product?.product_sort ?? ""}`,
              };
            })}
            showSearch
            allowClear
            loading={productsLoading}
            value={productId}
            onChange={e => {
              setProductId(e);
              setPageNumber(1);
            }}
          />
        </Col>
      </Row>

      <Table
        columns={productsColumns(pageNumber, pageSize)}
        dataSource={data?.results}
        className="mt-5"
        rowKey="id"
        bordered
        size="small"
        locale={{
          emptyText,
        }}
        scroll={{
          x: "300",
          y: "60vh",
        }}
        loading={isLoading}
        pagination={false}
        onChange={(obj: any) => {
          setPageNumber(obj.current);
          setPageSize(obj.pageSize);
        }}
      />
      <div className="text-center mt-5">
        <Pagination
          total={data?.count}
          current={pageNumber}
          pageSize={pageSize}
          showSizeChanger={true}
          pageSizeOptions={["20", "50", "100"]}
          onChange={pageNumber => {
            setPageNumber(pageNumber);
          }}
          onShowSizeChange={(current, pageSize) => {
            setPageSize(pageSize);
          }}
          showTotal={(total, range) => {
            return (
              <>
                {range[0]}-{range[1]} of {total} items
              </>
            );
          }}
          defaultPageSize={20}
        />
      </div>
    </div>
  );
};

export default Products;
