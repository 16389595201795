import { IStatus } from "types/status.type";

// export const IMG_BASE_URL = "http://192.168.1.234:8000";
export const IMG_BASE_URL = "https://narx.karantin.uz";
export const BASE_URL = `${IMG_BASE_URL}/api/v1/`;

export const productPackagingUnits = [
  { value: "KG", label: "KG (kilogramm)" },
  { value: "GR", label: "GR (gramm)" },
  { value: "TN", label: "TN (tonna)" },
  { value: "L", label: "L (litr)" },
  { value: "SHT", label: "dona" },
];

export const currencyUnits = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "RUB", label: "RUB" },
];

export const statusOptions: { value: IStatus; label: string }[] = [
  { value: "parsing", label: "Jarayonda" },
  { value: "parsing_failed", label: "Saqlashda xatolik" },
  { value: "parsing_success", label: "Saqlandi" },
  { value: "confirmed", label: "Tasdiqlandi" },
  { value: "rejected", label: "Bekor qilindi" },
];
