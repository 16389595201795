import { Flex, Popconfirm, Select, TableProps } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import { TbFilterFilled } from "react-icons/tb";
import { CountryInterface } from "types/server-data.types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { makeOptions } from "utils";

export const uploadedProductDetailModalColumns = (
  pageNumber: number,
  pageSize: number,
  countryLoading: boolean,
  selectedCountry: number | null,
  setSelectedCountry: (selectedCountry: number) => void,
  countries: CountryInterface[],
  openRowModal: any,
  submitDelete: any,
): TableProps["columns"] => [
  {
    title: `#`,
    key: "numberOfRow",
    fixed: "left",
    align: "center",
    width: 60,
    render: (text: string, obj: any, index: number) => {
      return <span> {(pageNumber - 1) * pageSize + index + 1} </span>;
    },
  },
  {
    title: "Davlat",
    key: "where_come_from_name",
    dataIndex: "where_come_from_name",
    align: "center",
    width: 200,
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Davlat tanlang"
          loading={countryLoading}
          value={selectedCountry}
          onChange={value => setSelectedCountry(value)}
          allowClear
          options={makeOptions(countries, "name")}
        />
      </div>
    ),
    filterIcon: filtered => <TbFilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
  },
  {
    title: "Shahar",
    key: "city_name",
    dataIndex: "city_name",
    align: "center",
    width: 200,
  },
  {
    title: "Mahsulot",
    key: "product_name",
    dataIndex: "product_name",
    align: "center",
    width: 200,
  },
  {
    title: "Mahsulot navi",
    key: "product_sort",
    dataIndex: "product_sort",
    align: "center",
    width: 200,
  },
  {
    title: "Minimum narxi",
    key: "market_min_price",
    dataIndex: "market_min_price",
    align: "center",
    width: 170,
  },
  {
    title: "O'rtacha narxi",
    key: "market_avg_price",
    dataIndex: "market_avg_price",
    align: "center",
    width: 170,
  },
  {
    title: "Maksimum narxi",
    key: "market_max_price",
    dataIndex: "market_max_price",
    align: "center",
    width: 170,
  },
  {
    title: `Amallar`,
    fixed: "right",
    key: "action",
    align: "center",
    width: 100,
    render: (row: any) => {
      return (
        <Flex gap={"10px"} justify="center">
          <div className="icon" onClick={() => openRowModal(row)}>
            <MdEdit className="cursor-pointer" size={20} />
          </div>
          <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            title={"O'chirmoqchimisiz?"}
            onConfirm={() => submitDelete(row?.id)}
            okText="Ha"
            cancelText="Yo'q"
            className={"shadow-lg overflow-hidden"}
          >
            <div className="icon">
              <MdDelete className="cursor-pointer" size={20} />
            </div>
          </Popconfirm>
        </Flex>
      );
    },
  },
];
