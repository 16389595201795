import { Select, TableProps, Tag, Tooltip } from "antd";
import { FiEye } from "react-icons/fi";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { TbFilterFilled } from "react-icons/tb";
import { statusOptions } from "constants/consts";

export const excelDataColumns = (
  pageNumber: number,
  pageSize: number,
  openDetailModal: (id: string) => void,
  status: string | null,
  setStatus: (id: string | null) => void,
): TableProps["columns"] => [
  {
    title: `#`,
    key: "numberOfRow",
    fixed: "left",
    align: "center",
    width: 50,
    render: (text: string, obj: any, index: number) => {
      return <span> {(pageNumber - 1) * pageSize + index + 1} </span>;
    },
  },
  {
    title: `Admin`,
    key: "user",
    dataIndex: "user",
    align: "center",
    width: 150,
  },
  {
    title: `Fayl`,
    key: "file",
    dataIndex: "file",
    align: "center",
    width: 150,
    render: (text: string, obj: any, index: number) => {
      return (
        <a href={text} target="_blank" rel="noreferrer">
          {obj.file_name}
        </a>
      );
    },
  },
  {
    title: `Status`,
    key: "status",
    align: "center",
    width: 150,
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Statusni tanlang"
          value={status}
          onChange={e => setStatus(e)}
          options={[
            {
              value: "",
              label: "Barchasi",
            },
            ...statusOptions,
          ]}
        />
      </div>
    ),
    filterIcon: filtered => <TbFilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
    render: (row: any) => {
      if (row.status === "parsing") {
        return (
          <Tag icon={<LoadingOutlined />} color="orange">
            Jarayonda
          </Tag>
        );
      } else if (row.status === "parsing_failed") {
        return (
          <Tooltip title={row?.parsing_info?.error}>
            <Tag icon={<CloseCircleOutlined />} color="red">
              Saqlashda xatolik
            </Tag>
          </Tooltip>
        );
      } else if (row.status === "parsing_success") {
        return (
          <Tag icon={<CheckCircleOutlined />} color="blue">
            Saqlandi
          </Tag>
        );
      } else if (row.status === "confirmed") {
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Tasdiqlandi
          </Tag>
        );
      } else if (row.status === "rejected") {
        return (
          <Tag icon={<CloseCircleOutlined />} color="red">
            Bekor qilindi
          </Tag>
        );
      }
    },
  },
  {
    title: `Tanlangan vaqt`,
    key: "data_date",
    dataIndex: "data_date",
    align: "center",
    width: 150,
  },
  {
    title: `Ma'lumotlar`,
    key: "action",
    align: "center",
    width: 100,
    render: (row: any) => {
      if (row?.status === "rejected" || row?.status === "parsing_failed") {
        return <EyeInvisibleOutlined className="text-[grey] text-[18px]" />;
      } else {
        return (
          <FiEye className="cursor-pointer text-[18px]" onClick={() => openDetailModal(row)} />
        );
      }
    },
  },
];
