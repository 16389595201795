import { TableProps, Typography } from "antd";
import queryString from "query-string";
import {
  IoIosAddCircleOutline,
  IoIosCheckmarkCircleOutline,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { Link } from "react-router-dom";
import { setUTCTime } from "utils";

const { Text } = Typography;

export const entryProductsColumns = (
  pageNumber: number,
  pageSize: number,
  openModal: (row: any) => void,
): TableProps["columns"] => [
  {
    title: `#`,
    key: "numberOfRow",
    dataIndex: "id",
    fixed: "left",
    align: "center",
    width: 60,
    render: (text: string, obj: any, index: number) => {
      return <span> {(pageNumber - 1) * pageSize + index + 1} </span>;
    },
  },
  {
    title: `Mahsulot nomi`,
    key: "product_name",
    align: "center",
    width: 200,
    render: (row: any) => {
      return (
        <Link
          to={`${row?.id}?${queryString.stringify({
            productName: row.product_name,
          })}`}
        >
          {row.product_name}
        </Link>
      );
    },
  },
  {
    title: `Oxirgi ma'lumot kiritilgan sana`,
    dataIndex: "last_price_date_modified",
    key: "last_price_date_modified",
    align: "center",
    width: 300,
    render: (date: string) =>
      date ? setUTCTime(date) : <Text className="text-[red]">Ma'lumot topilmadi</Text>,
  },
  {
    title: `${setUTCTime(`${new Date()}`, "YYYY-MM-DD")} dagi holat`,
    key: "today",
    align: "center",
    width: 300,
    render: (row: any) => {
      if (
        !row?.last_price_date_modified ||
        setUTCTime(row?.last_price_date_modified, "YYYY-MM-DD") !==
          setUTCTime(`${new Date()}`, "YYYY-MM-DD")
      ) {
        return (
          <Text className="text-[red]">
            Ma'lumot kiritilmagan <IoIosInformationCircleOutline size={18} />
          </Text>
        );
      } else {
        return (
          <Text className="text-[green]">
            Ma'lumot kiritilgan <IoIosCheckmarkCircleOutline size={18} />
          </Text>
        );
      }
    },
  },
  {
    title: `Malumot kirish`,
    key: "option",
    align: "center",
    width: 100,
    render: (row: any) => {
      return (
        <IoIosAddCircleOutline
          size={24}
          className="cursor-pointer"
          onClick={() => openModal(row)}
        />
      );
    },
    fixed: "right",
  },
];
