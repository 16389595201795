import { Upload } from "antd";
import type { UploadProps } from "antd/es/upload";
import type { UploadFile as UploadFileType } from "antd/es/upload/interface";
import http from "configs/http";
import { BASE_URL } from "constants/consts";
import { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";

export type FileResponse = {
  name: string;
  uploadPath: string;
} & Record<string, any>;

export const UploadFile = ({
  onSuccess: onSuccessEvent,
  onError: onErrorEvent,
  defaultFile,
  uploadPath,
  className = "",
  accept = "image/*",
  multiple,
  fileKeyName,
  dateString,
}: {
  onSuccess?: Function;
  onError?: Function;
  defaultFile?: string;
  uploadPath?: string;
  className?: string;
  accept?: string;
  multiple?: boolean;
  fileKeyName?: string;
  dateString?: string | null;
}) => {
  //   const [progress, setProgress] = useState(0);
  const [fileList, setFileList] = useState<UploadFileType[]>([]);
  const { usd } = useSelector((state: any) => state?.courseMoney);

  useEffect(() => {
    if (fileList.length === 0 && onSuccessEvent) {
      onSuccessEvent(null);
    }
  }, [fileList.length, onSuccessEvent]);

  useEffect(() => {
    setFileList(
      defaultFile
        ? [
            {
              uid: "1",
              name: "rasm",
              status: "done",
              url: `${BASE_URL}${defaultFile}`,
            },
          ]
        : [],
    );

    if (onSuccessEvent)
      onSuccessEvent(defaultFile ? { name: "", uploadPath: "public/" + defaultFile } : null);
  }, [defaultFile, onSuccessEvent]);

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    if (dateString) {
      fmData.append("data_date", dateString);
    }
    fmData.append(fileKeyName || "file", file);
    fmData.append("currency", usd?.Rate);

    try {
      const url = uploadPath || `${BASE_URL}`;
      const res = await http.post(url, fmData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          //   setProgress(percent);
          if (percent === 100) {
            // setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      });

      onSuccess("Ok");
      //   console.log("server res: ", res);
      if (onSuccessEvent) onSuccessEvent(res?.data || {});
    } catch (err) {
      //   console.log("Error: ", err);
      onError({ err });
      if (onErrorEvent) onErrorEvent({ err: "Error" });
    }
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (onSuccessEvent && newFileList.length === 0) onSuccessEvent(null);
  };

  return (
    <div className={className}>
      <Upload
        className="w-[100%]"
        accept={accept}
        customRequest={uploadImage}
        listType="picture-card"
        onChange={handleChange}
        fileList={fileList}
        multiple={multiple}
        locale={{
          uploading: "Yuklanyapti...",
          removeFile: "Faylni o'chirish",
          downloadFile: "Faylni yuklash",
          uploadError: "Noto'g'ri fayl yuklandi!",
          previewFile: "Faylni ko'rish",
        }}
        disabled={!dateString}
      >
        {fileList.length === 0 && (
          <div className={"flex items-center"}>
            <FiUpload className={"mr-1"} /> Yuklash
          </div>
        )}
      </Upload>
      {/* {progress > 0 ? <Progress percent={progress} /> : null} */}
    </div>
  );
};
