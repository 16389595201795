import { createSlice } from "@reduxjs/toolkit";

const initialMode = window.localStorage.getItem("mode");

export const antdThemeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: initialMode ?? "light",
  },
  reducers: {
    changeTheme: (state, action) => {
      state.mode = action.payload;
      window.localStorage.setItem("mode", action.payload);
    },
  },
});

export const { changeTheme } = antdThemeSlice.actions;
export default antdThemeSlice.reducer;
