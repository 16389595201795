import { BASE_URL } from "../constants/consts";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import Cookies from "js-cookie";

const initialLang = Cookies.get("i18next");

const token = Cookies.get("access_token");

const http: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": initialLang,
  },
});

http.interceptors.request.use((config: any) => {
  if (token) {
    config.headers = {
      Authorization: `Token ${token}`,
      "Accept-Language": initialLang,
    };
    return config;
  } else {
    return config;
  }
});

http.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      // toast.warning('Token has expired, Please login!')
      Cookies.remove("access_token");
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export function writeHeaders(token: string) {
  http.defaults.headers.common["Authorization"] = `Token ${token}`;
}

export default http;
