import { Image, TableProps, Tooltip, Typography } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import { CountryInterface, UserInterface } from "types/server-data.types";

const { Text } = Typography;

export const entryProductByIdColumns = (
  pageNumber: number,
  pageSize: number,
): TableProps["columns"] => [
  {
    title: `#`,
    key: "numberOfRow",
    fixed: "left",
    align: "center",
    width: 60,
    render: (text: string, obj: any, index: number) => {
      return <span> {(pageNumber - 1) * pageSize + index + 1} </span>;
    },
  },
  {
    title: `Davlat`,
    dataIndex: ["user", "country"],
    key: "country",
    align: "center",
    width: 200,
    render: (row: CountryInterface) => {
      return (
        <div className="flex items-center justify-center">
          <img src={row?.flag} alt={""} className="w-[30px] mr-1" />
          <Text>{row?.name}</Text>
        </div>
      );
    },
  },
  {
    title: `Xodim`,
    dataIndex: ["user"],
    key: "xodim",
    align: "center",
    width: 350,
    render: (row: UserInterface) => {
      return (
        <div className="flex items-center justify-center">
          <Text>
            {row?.first_name}
            <Tooltip
              placement="topRight"
              trigger={"click"}
              title={
                <>
                  <p>{row.email}</p>
                  <p className="m-0">{row.phone_number}</p>
                </>
              }
            >
              <BsInfoCircle className="cursor-pointer ml-2 mb-2" />
            </Tooltip>
          </Text>
        </div>
      );
    },
  },
  {
    title: `Mahsulot nomi`,
    dataIndex: "product",
    key: "product",
    align: "center",
    width: 300,
  },
  {
    title: `Mahsulot yetishtirilgan davlat`,
    dataIndex: "where_come_from",
    key: "where_come_from",
    align: "center",
    width: 300,
  },
  {
    title: `Mahsulot navi`,
    dataIndex: "product_sort",
    key: "product_sort",
    align: "center",
    width: 300,
  },
  {
    title: `Mahsulot qadog'i`,
    key: "product_package",
    align: "center",
    children: [
      {
        title: `Nomi`,
        dataIndex: "product_packaging",
        key: "product_packaging",
        width: 200,
        align: "center",
      },
      {
        title: `O'lchov qadog'i`,
        dataIndex: "product_packaging_size",
        key: "product_packaging_size",
        width: 200,
        align: "center",
      },
      {
        title: `O'lchov birligi`,
        dataIndex: "product_packaging_unit",
        key: "product_packaging_unit",
        width: 200,
        align: "center",
      },
    ],
  },
  {
    title: `Bozordagi narxi`,
    key: "market_price",
    align: "center",
    children: [
      {
        title: `Eng past narx`,
        dataIndex: "market_min_price",
        key: "market_min_price",
        width: 200,
        align: "center",
      },
      {
        title: `Eng baland narx`,
        dataIndex: "market_max_price",
        key: "market_max_price",
        width: 200,
        align: "center",
      },
      {
        title: `Valyuta`,
        dataIndex: "market_currency_type",
        key: "market_currency_type",
        width: 200,
        align: "center",
      },
    ],
  },
  {
    title: `Savdo tarmoqlaridagi narx`,
    key: "inner_price",
    align: "center",
    children: [
      {
        title: `Eng past narx`,
        dataIndex: "export_min_price",
        key: "export_min_price",
        width: 200,
        align: "center",
      },
      {
        title: `Eng baland narx`,
        dataIndex: "export_max_price",
        key: "export_max_price",
        width: 200,
        align: "center",
      },
      {
        title: `Valyuta`,
        dataIndex: "export_currency_type",
        key: "export_currency_type",
        width: 200,
        align: "center",
      },
    ],
  },
  {
    title: `Ma'lumot kiritilgan sana`,
    dataIndex: "modified_at",
    key: "modified_at",
    align: "center",
    width: 300,
  },
  {
    title: `Rasm`,
    key: "image",
    align: "center",
    width: 100,
    render: (row: any) => {
      return <Image width={60} src={row?.image} />;
    },
    fixed: "right",
  },
];
