import ExcelData from "pages/excel-data";
import { ROUTER } from "../constants/router";
import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Products from "pages/products";
import Help from "pages/help";
import EntryProducts from "pages/entry-products";
import EntryProductById from "pages/entry-product-by-id";

// LOGIN
const Login = lazy(async () => await import("../pages/login"));

// LAYOUT
const CustomLayout = lazy(async () => await import("../layout"));

export const adminRouter = createBrowserRouter([
  {
    // path: ROUTER.HOME,
    element: <CustomLayout />,
    children: [
      {
        path: ROUTER.PRODUCTS,
        element: <Products />,
      },
      {
        path: ROUTER.HELP,
        element: <Help />,
      },
      {
        path: ROUTER.EXCELDATA,
        element: <ExcelData />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={ROUTER.PRODUCTS} />,
  },
]);

export const tivHodimRouter = createBrowserRouter([
  {
    // path: ROUTER.HOME,
    element: <CustomLayout />,
    children: [
      {
        path: ROUTER.ENTRYPRODUCTS,
        element: <EntryProducts />,
      },
      {
        path: ROUTER.ENTRYPRODUCTBYID,
        element: <EntryProductById />,
      },
      {
        path: ROUTER.HELP,
        element: <Help />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={ROUTER.ENTRYPRODUCTS} />,
  },
]);

export const notFoundRouter = createBrowserRouter([
  {
    path: ROUTER.HOME,
    element: <CustomLayout />,
  },
  {
    path: "*",
    element: <Navigate to={ROUTER.ENTRYPRODUCTS} />,
  },
]);

export const loginRouter = createBrowserRouter([
  {
    path: ROUTER.LOGIN,
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to={ROUTER.LOGIN} />,
  },
]);
