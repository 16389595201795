export const ENDPOINTS = {
  API_ACCOUNT_LOGIN: "/account/login/",
  API_COMMON_COUNTRIES: "/common/countries/?for_web=true",
  API_COMMON_CITIES: "/common/cities/",
  API_COMMON_EXPORT_COUNTRIES: "/common/export/countries/",
  API_EXPORT_WEB_PRODUCTS: "/export/web/products/",
  API_EXPORT_WEB_PRODUCTS_PRICES: "/export/web/product/prices/",
  API_EXPORT_WEB_PRODUCT_PRICE: "/export/web/product/price/",
  API_EXPORT_WEB_PRODUCT_PACKAGES: "/export/web/product/packages/",
  API_EXPORT_WEB_PRODUCT_PRICE_DATA: "/export/web/product-price-data/",
  API_EXPORT_WEB_UPLOAD: "/export/web/upload/",
  API_EXPORT_WEB_UPLOAD_CONFIRM: "/export/web/upload/confirm/",
  API_EXPORT_WEB_UPLOAD_LIST: "/export/web/upload/list/",
  API_EXPORT_WEB_UPLOAD_DETAIL: "/export/web/upload/detail/",
};
