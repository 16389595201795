import { createSlice } from "@reduxjs/toolkit";

export const courseMoneySlice = createSlice({
  name: "auth",
  initialState: {
    usd: {},
    eur: {},
    rub: {},
  },
  reducers: {
    saveCourseMoney: (state, action) => {
      action.payload?.forEach((item: any) => {
        if (item.Ccy === "USD") {
          state.usd = item;
        }
        if (item.Ccy === "EUR") {
          state.eur = item;
        }
        if (item.Ccy === "RUB") {
          state.rub = item;
        }
      });
    },
  },
});

export const { saveCourseMoney } = courseMoneySlice.actions;
export default courseMoneySlice.reducer;
