import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Modal, Row, Table } from "antd";
import http from "configs/http";
import { ENDPOINTS } from "constants/endpoints";
import { QUERY_KEYS } from "constants/query-keys";
import { useMemo, useState } from "react";

import { UploadedProductDetailModalInterface } from "./props.types";
import { uploadedProductDetailModalColumns } from "./columns";
import { emptyText } from "constants/texts";
import UploadedProductDetailRowModal from "./modals/uploaded-product-detail-row-modal";
import { useExportCountries, useExportWebUploadDetail } from "hooks";
import { toast } from "react-toastify";

const UploadedProductDetailModal = (props: UploadedProductDetailModalInterface) => {
  const queryClient = useQueryClient();
  const { setIsOpenDetailModal, isOpenDetailModal, productId, rowStatus } = props;

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [isOpenUploadedProductDetailRowModal, setIsOpenUploadedProductDetailRowModal] =
    useState<boolean>(false);
  const [rowData, setRowData] = useState([]);

  const openRowModal = (data: any) => {
    setRowData(data);
    setIsOpenUploadedProductDetailRowModal(true);
  };

  const filter = useMemo(() => {
    return {
      page: pageNumber,
      page_size: pageSize,
      country_id: selectedCountry ?? undefined,
    };
  }, [pageNumber, pageSize, selectedCountry]);

  // HOOKS
  const { data: countries, isLoading: countryLoading } = useExportCountries(productId);

  const { data, isLoading } = useExportWebUploadDetail(filter, productId);

  const { mutate: submitDelete } = useMutation({
    mutationKey: [QUERY_KEYS.DELETE_EXPORT_WEB_PRODUCTS_PRICE_BY_ID],
    mutationFn: async (rowId): Promise<any> => {
      return await http.delete(`${ENDPOINTS.API_EXPORT_WEB_PRODUCT_PRICE}${rowId}/`);
    },
    onSuccess: (res: any) => {
      toast.success("Muvaffaqqiyatli o'chirildi!");
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_DETAIL],
      });
    },
  });

  const handleSubmit = async (id: number, isConfirmed: boolean) => {
    return await http.put(`${ENDPOINTS.API_EXPORT_WEB_UPLOAD_CONFIRM}${id}/`, {
      id,
      is_confirmed: isConfirmed,
    });
  };

  const { mutateAsync: submitAction, isPending: actionLoading } = useMutation({
    mutationKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_CONFIRM],
    mutationFn: ({ id, isConfirmed }: { id: number; isConfirmed: boolean }) =>
      handleSubmit(id, isConfirmed),
    onSuccess: () => {
      toast.success("Muvaffaqqiyatli!");
      setIsOpenDetailModal(false);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_LIST],
      });
    },
  });

  return (
    <Modal
      open={isOpenDetailModal}
      footer={[]}
      centered
      width="80%"
      title="Mahsulot ma'lumotlari"
      onCancel={() => setIsOpenDetailModal(false)}
    >
      {isOpenUploadedProductDetailRowModal ? (
        <UploadedProductDetailRowModal
          setIsOpenUploadedProductDetailRowModal={setIsOpenUploadedProductDetailRowModal}
          isOpenUploadedProductDetailRowModal={isOpenUploadedProductDetailRowModal}
          rowData={rowData}
        />
      ) : null}

      <Table
        columns={uploadedProductDetailModalColumns(
          pageNumber,
          pageSize,
          countryLoading,
          selectedCountry,
          setSelectedCountry,
          countries,
          openRowModal,
          submitDelete,
        )}
        dataSource={data?.results}
        className="mt-5"
        loading={isLoading}
        rowKey="id"
        bordered
        size="small"
        locale={{
          emptyText,
        }}
        scroll={{
          x: "300",
          y: "60vh",
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100"],
          total: data?.count,
        }}
        onChange={(obj: any) => {
          setPageNumber(obj.current);
          setPageSize(obj.pageSize);
        }}
      />

      {rowStatus === "parsing_success" ? (
        <Row className="mt-5">
          <Col className="ml-auto">
            <Button
              onClick={() =>
                submitAction({
                  id: productId,
                  isConfirmed: false,
                })
              }
              className="mr-5 px-5"
              loading={actionLoading}
            >
              Bekor qilish
            </Button>
            <Button
              type="primary"
              className="px-5"
              onClick={() =>
                submitAction({
                  id: productId,
                  isConfirmed: true,
                })
              }
              loading={actionLoading}
            >
              Tasdiqlash
            </Button>
          </Col>
        </Row>
      ) : null}
    </Modal>
  );
};

export default UploadedProductDetailModal;
