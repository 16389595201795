import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/authSlice";
import courseMoneySlice from "../reducers/courseMoneySlice";
import antdThemeSlice from "../reducers/antdThemeSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    courseMoney: courseMoneySlice,
    antdTheme: antdThemeSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});
