import { createSlice } from "@reduxjs/toolkit";
import { writeHeaders } from "../configs/http";
import Cookies from "js-cookie";

const accessToken = Cookies.get("access_token") ?? null;
const role = Cookies.get("role") ?? "admin";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken,
    isAuth: !!accessToken,
    role,
  },
  reducers: {
    userLogin: (state, action) => {
      state.accessToken = action.payload.token;
      state.isAuth = true;
      state.role = action.payload.role;

      Cookies.set("access_token", action.payload.token, { expires: 1 });
      Cookies.set("role", action.payload.role, { expires: 1 });

      localStorage.setItem("user", JSON.stringify(action.payload));

      writeHeaders(action.payload.token);
    },
    userLogout: state => {
      state.accessToken = null;
      state.isAuth = false;

      Cookies.remove("access_token");
      localStorage.removeItem("user");
      window.location.reload();
    },
  },
});

export const { userLogin, userLogout } = authSlice.actions;
export default authSlice.reducer;
