import { useQuery } from "@tanstack/react-query";
import { Table, Typography } from "antd";
import http from "configs/http";
import { ENDPOINTS } from "constants/endpoints";
import { QUERY_KEYS } from "constants/query-keys";
import { useState } from "react";
import { ProductInfoInterface } from "types/server-data.types";
import { entryProductsColumns } from "./columns";
import AddProductInfo from "./modals/add-product-info-modal";
import { emptyText } from "constants/texts";

const { Title } = Typography;

const EntryProducts = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [productInfo, setProductInfo] = useState<ProductInfoInterface | null>(null);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const openModal = (row: ProductInfoInterface) => {
    setProductInfo(row);
    setIsOpenModal(true);
  };

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_EXPORT_WEB_PRODUCTS],
    queryFn: async () => {
      const response = await http.get(ENDPOINTS.API_EXPORT_WEB_PRODUCTS);
      return response.data;
    },
  });

  return (
    <div>
      {isOpenModal ? (
        <AddProductInfo
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          productInfo={productInfo}
        />
      ) : null}

      <Title style={{ whiteSpace: "nowrap", overflowX: "auto" }} level={4}>
        Eksportbop meva-sabzavot mahsulotlarining xorijiy bozorlardagi eng past va baland narxlari
        to'g'risida.
      </Title>

      <Table
        columns={entryProductsColumns(pageNumber, pageSize, openModal)}
        dataSource={data}
        className="mt-5"
        rowKey="id"
        bordered
        size="small"
        locale={{
          emptyText,
        }}
        scroll={{
          x: "300",
          y: "70vh",
        }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        onChange={(obj: any) => {
          setPageNumber(obj.current);
          setPageSize(obj.pageSize);
        }}
      />
    </div>
  );
};

export default EntryProducts;
