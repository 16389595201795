import { Collapse } from "antd";
import type { CollapseProps } from "antd";

const Help = () => {
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Portaldan foydalanish bo'yicha video qo'llanma!",
      children: (
        <div className="text-center">
          <iframe
            src="https://www.youtube.com/embed/F4MRQg46tJc"
            title="Ro&#39;yxatdan o&#39;tish"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen={true}
            className="w-[100%] xl:h-[500px] lg:h-[400px] md:h-[350px] sm:h-[300px] xs:h-[300px]"
          ></iframe>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Collapse items={items} bordered={false} defaultActiveKey={["1"]} />
    </div>
  );
};

export default Help;
