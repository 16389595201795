export const QUERY_KEYS = {
  ACCOUNT_LOGIN: "account-login",
  GET_COMMON_COUNTRIES: "common-countries",
  GET_COMMON_EXPORT_COUNTRIES: "common-export-countries",
  GET_COMMON_CITIES: "common-cities",
  GET_EXPORT_WEB_PRODUCTS: "export-web-products",
  GET_EXPORT_WEB_PRODUCTS_PRICES: "export-web-products-prices",
  DELETE_EXPORT_WEB_PRODUCTS_PRICE_BY_ID: "export-web-products-price-by-id",
  GET_EXPORT_WEB_PRODUCT_PACKAGES: "export-web-product-packages",
  POST_EXPORT_WEB_PRODUCT_PACKAGES: "export-web-product-packages",
  POST_EXPORT_WEB_PRODUCT_PRICE_DATA: "export-web-product-price-data",
  GET_EXPORT_WEB_UPLOAD_CONFIRM: "export-web-product-upload-confirm",
  GET_EXPORT_WEB_UPLOAD_LIST: "export-web-product-upload-list",
  GET_EXPORT_WEB_UPLOAD_DETAIL: "export-web-product-upload-detail",
  GET_DASHBOARD: "export-web-products-dashboard",
};
