import { Button, Col, Form, InputNumber, Modal, Row, Select } from "antd";
import { UploadedProductDetailRowModalInterface } from "./props.types";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/query-keys";
import { ENDPOINTS } from "constants/endpoints";
import http from "configs/http";
import { makeOptions } from "utils";
import { useCities, useCountries, useExportWebProducts } from "hooks";
import { toast } from "react-toastify";

const UploadedProductDetailRowModal = (props: UploadedProductDetailRowModalInterface) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [countryId, setCountryId] = useState(null);
  const [city, setCity] = useState(null);

  const { isOpenUploadedProductDetailRowModal, setIsOpenUploadedProductDetailRowModal, rowData } =
    props;

  // HOOKS
  const { data: countries, isLoading: countriesLoading } = useCountries();
  const { data: cities, isLoading: citiesLoading } = useCities(countryId, countries);
  const { data: products, isLoading: productsLoading } = useExportWebProducts();

  const { mutate: submitForm, isPending } = useMutation({
    mutationKey: [QUERY_KEYS.ACCOUNT_LOGIN],
    mutationFn: async (data): Promise<any> => {
      return await http.patch(`${ENDPOINTS.API_EXPORT_WEB_PRODUCT_PRICE}${rowData?.id}/`, data);
    },
    onSuccess: (res: any) => {
      toast.success("Muvaffaqqiyatli!");
      setIsOpenUploadedProductDetailRowModal(false);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_EXPORT_WEB_UPLOAD_DETAIL],
      });
    },
  });

  const onFinish = (values: any) => {
    submitForm({ ...values, city, where_come_from: countryId });
  };

  useEffect(() => {
    form.setFieldsValue(rowData);
    setCountryId(rowData?.where_come_from);
    setCity(rowData?.city);
  }, [rowData, form]);

  return (
    <Modal
      open={isOpenUploadedProductDetailRowModal}
      footer={[]}
      centered
      width="70%"
      title="Mahsulotni tahrirlash"
      closeIcon={null}
    >
      <Form
        form={form}
        name="update"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Davlat">
              <Select
                className="w-[100%]"
                placeholder="Davlatni tanlang"
                options={makeOptions(countries ?? [], "name")}
                showSearch
                loading={countriesLoading}
                value={countryId}
                onChange={e => {
                  setCountryId(e);
                  setCity(null);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Shahar">
              <Select
                className="w-[100%]"
                placeholder="Shaharni tanlang"
                options={makeOptions(cities ?? [], "name")}
                showSearch
                loading={citiesLoading}
                value={city}
                onChange={e => setCity(e)}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="product" label="Mahsulot">
              <Select
                className="w-[100%]"
                placeholder="Mahsulotni tanlang"
                options={products?.map((product: any) => {
                  return {
                    value: product?.id,
                    label: `${product?.product_name ?? ""} ${product?.product_sort ?? ""}`,
                  };
                })}
                showSearch
                loading={productsLoading}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="market_min_price" label="Minimum narxi">
              <InputNumber min={0} className="w-full" placeholder="0" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="market_avg_price" label="O'rtacha narxi">
              <InputNumber min={0} className="w-full" placeholder="0" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="market_max_price" label="Maksimum narxi">
              <InputNumber min={0} className="w-full" placeholder="0" />
            </Form.Item>
          </Col>

          <Col span={24} className="text-right">
            <Button onClick={() => setIsOpenUploadedProductDetailRowModal(false)}>
              Bekor qilish
            </Button>
            <Button type="primary" htmlType="submit" loading={isPending} className="ml-3">
              Saqlash
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UploadedProductDetailRowModal;
